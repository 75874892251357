import { create } from "mobx-persist";
import { enableStaticRendering } from "mobx-react";

import { AdvancedSearchStore } from "./AdvancedSearchStore";
import { ArticleCollectionStore } from "./ArticleCollectionStore";
import { AuthenticationStore } from "./AuthenticationStore";
import { AuthorsStore } from "./AuthorsStore";
import { CollectionSliderStore } from "./CollectionSliderStore";
import { CollectionStore } from "./CollectionStore";
import { ContactFormStore } from "./ContactFormStore";
import { ContextStore } from "./ContextStore";
import { CookiesStore } from "./CookiesStore";
import { FilterStore } from "./FilterStore";
import { InternalDataStore } from "./InternalDataStore";
import { KeywordStore } from "./KeywordStore";
import { LanguageStore } from "./LanguageStore";
import { LoginStore } from "./LoginStore";
import { MenuStore } from "./MenuStore";
import { NavigationStore } from "./NavigationStore";
import { NewsletterSignupStore } from "./NewsletterSignupStore";
import { PreferenceStore } from "./PreferenceStore";
import { RecommendationStore } from "./RecommendationStore";
import { RegisterStore } from "./RegisterStore";
import { SearchStore } from "./SearchStore";
import { SectionsContainerStore } from "./SectionsContainerStore";
import { SubscriptionStore } from "./SubscriptionStore";
import { UIStore } from "./UIStore";
import { UploadDocumentsStore } from "./UploadDocumentsStore";
import { UserStore } from "./UserStore";
import { ValidationStore } from "./ValidationStore";
import { ApiClient, GraphqlClient } from "~libs/api";
import { IContext } from "~models/Context";
import { ISingleWordpressLanguage } from "~models/Languages";
import { ILocation } from "~models/Location";
import { ISingleWordpressMenu } from "~models/Menu";
import { TaxonomyStore } from "./TaxonomyStore";

enableStaticRendering(typeof window === "undefined");
interface Props {
  store: Store;
  pageContext: IContext;
  location: ILocation;
  defaultLanguage: ISingleWordpressLanguage;
  languages: ISingleWordpressLanguage[];
  menus: ISingleWordpressMenu[];
}

export class Store {
  uiStore: UIStore;
  contextStore: ContextStore;
  navigationStore: NavigationStore;
  collectionSliderStore: CollectionSliderStore;
  newsletterSignupStore: NewsletterSignupStore;
  sectionsContainerStore: SectionsContainerStore;
  languageStore: LanguageStore;
  filterStore: FilterStore;
  advancedSearchStore: AdvancedSearchStore;
  keywordStore: KeywordStore;
  searchStore: SearchStore;
  authorsStore: AuthorsStore;
  collectionStore: CollectionStore;
  userStore: UserStore;
  loginStore: LoginStore;
  registerStore: RegisterStore;
  validationStore: ValidationStore;
  authenticationStore: AuthenticationStore;
  menuStore: MenuStore;
  uploadDocumentsStore: UploadDocumentsStore;
  subscriptionStore: SubscriptionStore;
  contactFormStore: ContactFormStore;
  cookiesStore: CookiesStore;
  articleCollectionStore: ArticleCollectionStore;
  internalDataStore: InternalDataStore;
  preferenceStore: PreferenceStore;
  recommendationStore: RecommendationStore;
  taxonomyStore: TaxonomyStore;

  constructor(props: Props) {
    const { pageContext, location, languages, menus } = props || {};
    const unAuthenticatedClient = new ApiClient(false);
    const authenticatedClient = new ApiClient(true);
    const graphqlClient = new GraphqlClient();
    this.taxonomyStore = new TaxonomyStore(this, authenticatedClient);
    this.uiStore = new UIStore();
    this.contextStore = new ContextStore(pageContext);
    this.languageStore = new LanguageStore(this, languages, pageContext?.lang || "en");
    this.navigationStore = new NavigationStore(this, location);
    this.collectionSliderStore = new CollectionSliderStore(this, unAuthenticatedClient);
    this.newsletterSignupStore = new NewsletterSignupStore(this, unAuthenticatedClient, authenticatedClient);
    this.sectionsContainerStore = new SectionsContainerStore(this);
    if (this.navigationStore.isStartPage) {
      this.filterStore = new FilterStore(this, unAuthenticatedClient, true);
    } else {
      this.filterStore = new FilterStore(this, unAuthenticatedClient, false);
    }
    this.advancedSearchStore = new AdvancedSearchStore(this);
    this.keywordStore = new KeywordStore(this);
    this.searchStore = new SearchStore(this, unAuthenticatedClient, authenticatedClient);
    this.authorsStore = new AuthorsStore();
    this.collectionStore = new CollectionStore(this);
    this.userStore = new UserStore(this, graphqlClient);
    this.loginStore = new LoginStore(this, graphqlClient, unAuthenticatedClient);
    this.registerStore = new RegisterStore(this, unAuthenticatedClient);
    this.validationStore = new ValidationStore();
    this.authenticationStore = new AuthenticationStore(this, authenticatedClient, graphqlClient);
    this.menuStore = new MenuStore(this, menus, "en");
    this.uploadDocumentsStore = new UploadDocumentsStore(this, authenticatedClient);
    this.subscriptionStore = new SubscriptionStore(this, authenticatedClient);
    this.contactFormStore = new ContactFormStore();
    this.cookiesStore = new CookiesStore();
    this.articleCollectionStore = new ArticleCollectionStore(this, unAuthenticatedClient);
    this.internalDataStore = new InternalDataStore(this, graphqlClient, authenticatedClient);
    this.preferenceStore = new PreferenceStore(this, graphqlClient, unAuthenticatedClient, authenticatedClient);
    this.recommendationStore = new RecommendationStore(this, graphqlClient, authenticatedClient);
  }

  onMount = () => {
    hydrate("authenticationStore", this.authenticationStore).then(async () => {
      if (typeof window !== undefined) {
        await this.authenticationStore.onMount();
      }
      this.authenticationStore.setHydrated(true);
    });

    hydrate("userStore", this.userStore).then(() => this.userStore.setHydrated(true));

    hydrate("languageStore", this.languageStore).then(() => {
      if (typeof window !== undefined) {
        this.languageStore.onMount();
      }
      this.languageStore.setCurrentLanguage();
      this.languageStore.setHydrated(true);
    });

    hydrate("filterStore", this.filterStore);

    this.uiStore.onMount();
    this.uploadDocumentsStore.onMount();
    this.registerStore.onMount();
  };

  onUnmount = () => {
    this.uiStore.onUnmount();
    this.uploadDocumentsStore.onUnmount();
    this.registerStore.onUnmount();
    this.authenticationStore.onUnmount();
  };
}

export const hydrate = create({});
